<template>
  <div class="container mb-4">
    <div class="row">
      <div class="col-12 header-col">
        <h1 class="title-w1">Glossary</h1>
      </div>
    </div>
  </div>
  <div class="container glossary-container">
    <!-- Desktop version -->
    <div class="row glossary-row">
      <div
        class="col-12 mb-4"
        v-for="(category, index) in glossary"
        :key="index"
      >
        <div class="table-subsection">
          <h2>{{ category.title }}</h2>
        </div>
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col">Acronym</th>
              <th scope="col">Full text</th>
              <th scope="col">Meaning</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in category.items" :key="item.acronym">
              <td class="text-bold">{{ item.acronym }}</td>
              <td>{{ item.full_text }}</td>
              <td class="mobile-hidden">{{ item.meaning }}</td>
              <td class="mobile-only">
                <span class="info-btn" @click="showDef(item)"
                  ><i class="bi bi-info-circle"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <el-dialog v-model="showModal" :title="activeItem.acronym" custom-class="info-modal">
    <div class="row">
        <div class="col-12">
            <p class="text-italic">{{ activeItem.full_text}}</p>
            <p>{{ activeItem.meaning}}</p>
        </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showModal = false"
          >Continue</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import glossary from '@/utils/data/glossary';

export default {
  name: 'GlossaryPage',
  data() {
    return {
      glossary,
      showModal: false,
      activeItem: {
        acronym: '',
        full_text: '',
        meaning: '',
      },
    };
  },
  methods: {
    showDef(item) {
      this.activeItem = item;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
.glossary-container {
  padding-bottom: 5rem;
}

.glossary-row {
  .table-subsection {
    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}
</style>
