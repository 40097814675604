// {
//     acronym: '',
//     full_text: '',
//     meaning: ""
// }

export default [
  {
    title: 'Related to hardware design',
    items: [
      {
        acronym: 'CPU',
        full_text: 'Central Processing Unit',
        meaning:
          "The core processor that executes most code on a server or computer. You can't have data centres without CPUs.",
      },
      {
        acronym: 'GPU',
        full_text: 'Graphics Processing Unit',
        meaning:
          'A specialized processor designed for graphics rendering. GPUs can also handle AI/ML workloads, which is the main reason they are sometimes deployed on servers inside data centres.',
      },
      {
        acronym: 'MTBF',
        full_text: 'Mean Time Between Failure',
        meaning:
          'The predicted elapsed time between inherent failures of a mechanical or electronic system during normal system operation. ',
      },
      {
        acronym: 'MTTD',
        full_text: 'Mean Time To Detect',
        meaning: '',
      },
      {
        acronym: 'MTTR',
        full_text: 'Mean Time To Repair',
        meaning:
          'The mean time it takes to remediate or resolve a problem. Like MTTD, MTTR can be tracked to measure data centre reliability effectiveness.',
      },
      {
        acronym: 'NIC',
        full_text: 'Network Interface Card',
        meaning:
          'A hardware device that provides network connectivity for a server or computer. In a data centre, the NICs you select for servers may determine how much network bandwidth they can handle, as well as how many physical network connections they can support.',
      },
      {
        acronym: 'PCIe',
        full_text: 'Peripheral Component Interconnect Express',
        meaning:
          'An interface design standard commonly used by add-on hardware devices that users attach to a motherboard, such as GPUs.',
      },
      {
        acronym: 'PSU',
        full_text: 'Power Supply Unit',
        meaning: '',
      },
      {
        acronym: 'RAM',
        full_text: 'Random Access Memory',
        meaning: '',
      },
      {
        acronym: 'RAID',
        full_text: 'Redundant Array of Independent Disks',
        meaning:
          'A means of configuring multiple disks such that they operate as a single storage unit, with data spread or replicated across multiple disks. RAIDs can increase data reliability, as well as data transfer rates.',
      },
      {
        acronym: 'SAS',
        full_text: 'Serial Attached SCSI',
        meaning:
          'A data transfer protocol. SAS is generally faster than SATA, but slower than NVMe.',
      },
      {
        acronym: 'SATA',
        full_text: 'Serial ATA',
        meaning:
          'A data transfer protocol. SATA is among the slowest data transfers still in widespread use, although it offers the benefit of broad hardware compatibility and support for storage devices that support large volumes of data.',
      },
      {
        acronym: 'SSD',
        full_text: 'Solid State Drive',
        meaning:
          'A type of storage device that stores data on flash memory. SSD devices typically deliver faster read and write speeds than traditional hard disks, which store data on magnetic platters.',
      },
    ],
  },
  {
    title: 'Related to Data Centre',
    items: [
      {
        acronym: 'OCP',
        full_text: 'Open Compute Project',
        meaning: "A nonprofit organization that promotes the sharing of data centre technology designs and best practices information among major technology companies.",
      },
      {
        acronym: 'OEM',
        full_text: 'Original Equipment Manufacturer',
        meaning: "The vendor that originally produced a product, even if the product was integrated into a different system or resold by another vendor. For example, Western Digital could be the OEM of a disk drive in a server manufactured by Dell.",
      },
      {
        acronym: 'PUE',
        full_text: 'Power Usage Efficiency',
        meaning: "A metric for tracking how much of the total energy consumed by a data centre is used to power computing equipment. The higher your PUE, the more energy-efficient your data centre is. ",
      },
      {
        acronym: 'EEE',
        full_text: '',   
        meaning: '',
      },
      {
        acronym: 'WEEE',
        full_text: '',
        meaning: '',
      },
    ],
  },
  {
    title: 'Related to Circular Economy',
    items: [
      {
        acronym: 'EDGs',
        full_text: 'Eco-design Guidelines',
        meaning: '',
      },
      {
        acronym: 'CE',
        full_text: 'Circular Economy',
        meaning: "An economic model, based on sharing, leasing, reusing, repairing, refurbishing and recycling existing products and materials for as long as possible.",
      },
      {
        acronym: 'LE',
        full_text: 'Linear Economy',
        meaning: "AKA the take-make-waste economy – a detrimental to the environment, a one-way system that relies on the perpetual extraction of natural resources to make products without consideration of the full potential for products and materials. In this system, the products and materials eventually end up as waste with little or no raw material reclamation being involved.",
      },
      {
        acronym: 'LCA',
        full_text: 'Life Cycle Assessment',
        meaning: '',
      },
      {
        acronym: 'LCC',
        full_text: 'Life Cycle Costing',
        meaning: "An estimate of all costs encountered by the producer throughout the lifespan of the configured equipment. These costs involve the initial investment, operating costs, maintenance, repair, upgrades, and disposal, excluding salvage value. It helps procurement make decisions about purchasing the equipment.",
      },
      {
        acronym: 'S-LCA',
        full_text: 'Social Life Cycle Assessment',
        meaning: "A method of assessing real and potential social and sociological aspects of organisations, products and/or services, including positive and negative contributions throughout the life cycle. ",
      },
      {
        acronym: 'PL',
        full_text: 'Product Lifetime',
        meaning: '',
      },
      {
        acronym: 'EoL',
        full_text: 'End of Life',
        meaning: "The product end-of-life stage means the product has reached the end of its usable life and is either sent for re-manufacture or refurbishment to extend product life, or it is sent to recycling or landfill. ",
      },
      {
        acronym: 'FRAND',
        full_text: 'Fair, Reasonable and Non-Discriminatory',
        meaning: 'Often used in relation to technical standards that are established through an open, consensus-based, and industry-led standardization process. ',
      },
    ],
  },
];
